@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100vw;
  overflow-x: auto;
}

textarea {
  resize: none;
}

.circle {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-flow: row;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #d44343;
  border-radius: 200px;
  pointer-events: none;
  color: #fff;
  text-align: center;
  font-size: 16px;
}
